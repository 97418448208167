import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
// ---------------------------------------------
import Header from "../components/header"
import Layout from "../components/layout"
import LightboxLink from "../components/lightbox/link"
import Transition from "../components/transition"
import SEO from "../components/seo"

const InformationPage = ({ data, location }) => {
  const { offices, longText } = data.craft.entries[0]

  const officeBlocks = []
  offices.forEach(field => {
    switch (field.__typename) {
      case "Craft_offices_office_BlockType":
        officeBlocks.push(field)
        break
    }
  })

  return (
    <Layout>
      <SEO title="Get in touch" />
      <Transition
        location={location}
        render={handleLink => (
          <>
            <LightboxLink handleLink={handleLink} />
            <Header handleLink={handleLink} />
            <main className="head-offset">
              <div className="info centred">
                <div className="info__row">
                  <ReactMarkdown
                    source={longText}
                    escapeHtml={false}
                    className="copy-row"
                  />
                </div>

                <section className="offices">
                  {officeBlocks.map(office => (
                    <div className="office" key={`office-${office.id}`}>
                      <h4 className="office__place">{office.place}</h4>
                      <address class="office__address">
                        <p>{office.address}</p>
                      </address>
                      <div className="office__agents">
                        {office.agents.map(agent => (
                          <OfficeAgent
                            key={`agent-${agent.id}`}
                            agent={agent}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </section>
              </div>
            </main>
          </>
        )}
      />
    </Layout>
  )
}

const OfficeAgent = ({ agent }) => {
  const { name, title, email, phone } = agent

  return (
    <div className="office__agent">
      <div>
        <span className="value uppercase whitespace-nowrap">{name}</span>
        <span className="label">{title}</span>
      </div>

      <div>
        <span className="label">Email</span>
        <span className="value">
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            {email}
          </a>
        </span>
      </div>
      <div>
        <span className="label">Call</span>
        <span className="value">{phone}</span>
      </div>
    </div>
  )
}

InformationPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default InformationPage

export const pageQuery = graphql`
  query InformationPageQuery {
    craft {
      entries(type: "Information") {
        ... on Craft_information_information_Entry {
          longText
          offices {
            ... on Craft_offices_office_BlockType {
              id
              place
              address
              agents {
                name
                title
                phone
                email
              }
            }
          }
        }
      }
    }
  }
`
